import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useFetchNotificationOptions } from "../../../../Hooks/Notification";
import styles from "./Editor.module.css";
import Loader from "../../../Moecules/Loader/Loader";

const TinyMCEEditor = ({
  name,
  value,
  onChange,
  errors,
  touched,
  label = "Email Content",
}) => {
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState(value || "");

  const { data: notificationOptions, isLoading } =
    useFetchNotificationOptions();

  const handleDropdownClick = (value) => {
    if (editorRef.current) {
      editorRef.current.execCommand("mceInsertContent", false, value);
    }
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
    if (onChange) {
      onChange(name, content);
    }
  };

  useEffect(() => {
    if (value) {
      setEditorContent(value);
    }
  }, [value]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles["email-content"]}>
      <h4>
        {label} <span>{errors && touched ? errors : ""}</span>
      </h4>
      <Editor
        apiKey="ix4nzrgnkh0ts7dw4q3lpma25x2utwws259zala47d503sbx"
        value={editorContent}
        onEditorChange={handleEditorChange}
        height="100"
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          autoresize: false,
          statusbar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "media",
            "paste",
            "wordcount",
            "editimage",
            "advcode",
          ],
          menubar: false,
          toolbar: `
            undo redo | styles|
            bold italic underline | fontfamily | fontsize |
            align | lineheight|
            bullist numlist outdent indent | 
            forecolor backcolor | 
            link image | 
            customDropdown|code
          `,
          image_title: true,
          automatic_uploads: true,
          file_picker_types: "image",
          // pad_empty_with_br: true,
          newline_behavior: "linebreak",
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.addEventListener("change", (e) => {
              const file = e.target.files[0];
              const reader = new FileReader();

              reader.addEventListener("load", () => {
                const id = "blobid" + new Date().getTime();
                const blobCache = editorRef.current.editorUpload.blobCache;
                const base64 = reader.result.split(",")[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                cb(blobInfo.blobUri(), { title: file.name });
              });

              reader.readAsDataURL(file);
            });

            input.click();
          },
          setup: (editor) => {
            editor.ui.registry.addMenuButton("customDropdown", {
              text: "Insert Option",
              fetch: (callback) => {
                const items = notificationOptions?.data?.map((option) => ({
                  type: "menuitem",
                  text: option.label,
                  onAction: () => handleDropdownClick(option.value),
                }));
                callback(items);
              },
            });
          },
        }}
      />
    </div>
  );
};

export default TinyMCEEditor;
