import React, { useEffect, useRef, useState } from "react";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import Buttons from "../../Atoms/Buttons/Buttons";
import plus from "../../../assets/Imagess/plusvector.svg";
import styles from "./EmailPage.module.css";
import EmailTable from "../../Moecules/Email/EmailTable";
import { useNavigate } from "react-router-dom";
import {
  useChangeEmailStatus,
  useDeleteEmail,
  useDuplicateEmailTemplate,
  useExportNotificationTable,
  useFetchNotifications,
} from "../../../Hooks/Notification";
import DeletePopup from "../../Moecules/Custom/DeletePopup";
import { useEmailContext } from "../../../Context/EmailContext";
import EmailFilter from "../../Moecules/Filter/EmailFilter";
import { toast } from "react-toastify";
import TableTopHeader from "../../Moecules/TableTopHeader/TableTopHeader";
import * as tableHeader from "../../Moecules/TableHeader/EmailHeader";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";
import dublicateIcon from "../../../assets/Imagess/Audience/dublicate.svg";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import TableComponent from "../../Moecules/TableComponent/TableComponent";
import { useSearchFilterContext } from "../../../Context/SearchFilterContext";
import TableFilter from "../../Moecules/Custom/TableFilter/TableFilter";

const EmailPage = () => {
  const navigate = useNavigate();
  const printRef = useRef();
  const { filterState, updateFilterState, resetFilterState } =
    useSearchFilterContext();
  const {
    searchValue,
    orderByValue,
    orderDirValue,
    filteredColumn,
    isFilter,
    page,
    pageSize,
    filteredByField,
  } = filterState;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState({});
  const [loadingToggleIndex, setLoadingToggleIndex] = useState(null);
  const [emailType, setEmailType] = useState("");
  const {
    data: emails,
    refetch: refetchEmails,
    isLoading: isEmailLoading,
  } = useFetchNotifications({
    emailType: emailType,
    searchBy: searchValue,
    isCampaign: false,
    pageSize: pageSize,
    pageNumber: page,
    desc: orderDirValue == "desc" ? false : true,
    sortBy: orderByValue || "NotificationName",
    filterColumns: filteredColumn,
  });
  const { mutate: deleteEmail, isLoading: isDeletingEmail } = useDeleteEmail();
  const { mutate: duplicateEmail, isLoading: isDuplicating } =
    useDuplicateEmailTemplate();
  const { setNewSelectedEmail } = useEmailContext();
  const exporNotifcationTable = useExportNotificationTable(0);
  const changeEmailStatus = useChangeEmailStatus();
  const headerMap = [
    { title: "Name", key: "notificationName" },
    { title: "Email Type", key: "notificationType_Display" },
    { title: "Attachment", key: "hasAttachment" },
    { title: "Created By", key: "userCreated" },
    { title: "Status", key: "isActive", flag: "switch" },
  ];

  const tableData = {
    data: {
      ...emails?.data,
      items: emails?.data?.items.map((item) => ({
        ...item,
        id: item.notificationId,
        foreignId: item.notificationId,
      })),
    },
    refetch: refetchEmails,
    isLoading: isEmailLoading,
    tableHeader: headerMap,
  };

  const handleFilterUpdate = (field, value) => {
    updateFilterState(field, value);
  };

  const handleDelete = () => {
    if (selectedEmail && selectedEmail.notificationId) {
      deleteEmail(selectedEmail.notificationId, {
        onSuccess: () => {
          setShowDeleteModal(false);
          refetchEmails();
          toast.success("Deleted successfully");
        },
        onError: (error) => {
          toast.error("You can't delete an active email");
          setShowDeleteModal(false);
        },
      });
    }
  };
  const handleExport = async () => {
    try {
      const data = await exporNotifcationTable.mutateAsync();
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "EmailTable";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected string.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleEdit = (e, item) => {
    e.stopPropagation();
    navigate(`/home/new-email/${item.notificationId}`, {
      state: { isCampaign: false },
    });
  };

  const handleNavigate = () => {
    navigate("/home/new-email", { state: { isCampaign: false } });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleDuplicate = () => {
    duplicateEmail(selectedEmail.notificationId, {
      onSuccess: () => {
        setIsDuplicate(false);
        refetchEmails();
        setSelectedEmail({});
        toast.success("Email is duplicated  successfully");
      },
      onError: (error) => {
        setIsDuplicate(false);
        setSelectedEmail({});
      },
    });
  };

  const handleToggleChange = async (index) => {
    setLoadingToggleIndex(index);
    const updatedData = [...tableData?.data?.items];
    const updatedEmail = updatedData?.find(
      (item) => item.notificationId == index
    );
    const originalState = updatedEmail?.isActive;
    updatedEmail.isActive = !originalState;
    try {
      await changeEmailStatus.mutate(
        {
          NotificationId: updatedEmail.notificationId,
          isActive: updatedEmail.isActive,
        },
        {
          onSuccess: () => {
            refetchEmails();
            setLoadingToggleIndex(null);
            toast.success(updatedEmail.isActive ? "Email Activated" : "Email Deactivated");
          },
          onError: () => {
            updatedEmail.isActive = originalState;
            setLoadingToggleIndex(null);
            toast.error(
              "We can't turn off since at least one email of the same type should be on"
            );
          },
        }
      );
    } catch (error) {
      updatedEmail.isActive = originalState;
      setLoadingToggleIndex(null);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };
  const additionalButtons = (
    <Buttons
      text='New Template'
      icon={plus}
      rounded={true}
      color='#F3223C'
      // to='/home/new-email'
      handleOnClick={handleNavigate}
    />
  );

  const rightButtons = (
    <TableFilter
      isFilter={isFilter}
      setIsFilter={(value) => handleFilterUpdate("isFilter", value)}
      setFilterByField={(value) => handleFilterUpdate("filteredByField", value)}
      setFilteredColumn={(value) => handleFilterUpdate("filteredColumn", value)}
    />
  );

  const additionaIcon = (item) => (
    <Tooltip content='Duplicate Icon'>
      <img
        src={dublicateIcon}
        alt='dublicate'
        onClick={(e) => (
          setIsDuplicate((prev) => !prev),
          setSelectedEmail(item),
          e.stopPropagation()
        )}
      />
    </Tooltip>
  );

  return (
    <>
      <WrappingContainer>
        <div className={styles["email-container"]}>
          <h2>Email Templates</h2>
          <TableTopHeader
            isSearch
            search={searchValue}
            setSearch={(value) => handleFilterUpdate("searchValue", value)}
            rightButtons={rightButtons}
            additionalButtons={additionalButtons}
            handleExport={handleExport}
            handlePrint={handlePrint}
          />
          {/* <TableComponent/> */}
          <PrintComponent ref={printRef} title='Emails Table'>
            <TableComponent
              tableData={tableData}
              orderDir={orderDirValue}
              orderBy={orderByValue}
              setOrderBy={(value) => handleFilterUpdate("orderByValue", value)}
              setOrderDir={(value) =>
                handleFilterUpdate("orderDirValue", value)
              }
              additionalIcon={additionaIcon}
              setPage={(value) => handleFilterUpdate("page", value)}
              pageSize={pageSize}
              page={page}
              setPageSize={(value) => handleFilterUpdate("pageSize", value)}
              pagination
              isOrder
              handleEditClick={handleEdit}
              handleDelete={(e, item) => (
                setShowDeleteModal(true),
                setSelectedEmail(item),
                e.stopPropagation()
              )}
              handleToggleChange={handleToggleChange}
              loadingToggleIndex={loadingToggleIndex}
              editAdd
              setFilteredColumn={(value) =>
                handleFilterUpdate("filteredColumn", value)
              }
              filteredByField={filteredByField}
              isFilter={isFilter}
              setFilterByField={(value) =>
                handleFilterUpdate("filteredByField", value)
              }
            />
          </PrintComponent>
        </div>
      </WrappingContainer>
      {showDeleteModal && (
        <DeletePopup
          handleOnClose={() => (
            setShowDeleteModal(false), setSelectedEmail({})
          )}
          title={selectedEmail?.notificationName}
          handleDelete={handleDelete}
          isLoading={isDeletingEmail}
        />
      )}
      {isDuplicate && (
        <DeletePopup
          mode='Duplicate'
          handleOnClose={() => (setIsDuplicate(false), setSelectedEmail({}))}
          title={selectedEmail?.notificationName}
          handleDelete={handleDuplicate}
          text='Are you want to duplicate'
          isLoading={isDuplicating}
        />
      )}
    </>
  );
};

export default EmailPage;
