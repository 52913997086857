import React, { useEffect, useState, useRef } from "react";
import styles from "./NewTemplate.module.css";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import subjectICon from "../../../assets/Imagess/subjectIcon.svg";
import emailICon from "../../../assets/Imagess/emaildropdownicon.svg";
import TextInput from "../../Atoms/Inputs/TextInput";
import * as Yup from "yup";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import { Formik, Form, useFormik, FormikProvider } from "formik";
import {
  useAddNotification,
  useFetchEmailTypes,
  useFetchNotificationsById,
} from "../../../Hooks/Notification";
import CautionIcon from "../../../assets/Imagess/cautionIconApplicant.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEmailContext } from "../../../Context/EmailContext";
import EmailTextEditor from "../../Moecules/Email/EmailTextEditor";
import TitlePageHeader from "../../Moecules/Settings/SettingsMolecules/TitlePageHeader";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import Attachments from "../../Atoms/Documents/Attachments";
import {
  useFetchESignature,
  useFetchUnpagedESignature,
} from "../../../Hooks/ESignature";
import Loader from "../../Moecules/Loader/Loader";
import { toast } from "react-toastify";
import Toggle from "../../Atoms/Inputs/Toggle";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import TinyMCEEditor from "../../Atoms/Inputs/TinyMCEEditor/TinyMCEEditor";

const NewTemplateEmail = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [isCampaign, setIsCampaign] = useState(
    location?.state?.isCampaign || false
  );
  const [init, setInit] = useState({});
  const [requiresOneActive, setRequiresOneActive] = useState(false);
  const { mutate: addEmail, isLoading: isAdding } = useAddNotification();
  const {
    data: notificationsById,
    isLoading,
    refetch: refetchNotificationById,
  } = useFetchNotificationsById(id);
  const { data: emailTypesData } = useFetchEmailTypes();
  const { data: eSignature } = useFetchUnpagedESignature();
  const notificationData =
    notificationsById?.data && notificationsById?.data[0];

  const validationSchema = Yup.object().shape({
    NotificationName: Yup.string().required(),
    NotificationId: Yup.string(),
    NotificationType: Yup.number().required(),
    NotificationContent: Yup.string().required("Required"),
    Subject: Yup.string().required(),
    EmailSignatureId: Yup.string(),
    Attachments: Yup.array().notRequired(),
    IsActive: Yup.boolean().required("isActive is required"),
  });

  const selectApplicantOptions = emailTypesData?.data?.map(
    ({ notificationTypeId, notificationType }) => ({
      value: notificationTypeId,
      text: notificationType,
    })
  );
  useEffect(() => {
    const initialValues = {
      NotificationName: notificationData?.notificationName || "",
      NotificationId:
        notificationData?.notificationId ||
        "00000000-0000-0000-0000-000000000000",
      NotificationType: isCampaign
        ? 4
        : notificationData?.notificationType || "",
      NotificationContent: notificationData?.notificationContent || "",
      Subject: notificationData?.subject || "",
      EmailSignatureId: notificationData?.emailSignatureId || "",
      Attachments: notificationData?.attachments || [],
      IsActive: notificationData?.isActive || false,
    };
    setInit(initialValues);
  }, [notificationData, id]);

  const formik = useFormik({
    initialValues: init,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      let appendIndex = 0;
      // Handle attachments
      values.Attachments.forEach((attachment, index) => {
        const fileId = attachment.attachmentId;
        //if new file
        if (!fileId) {
          formData.append(`Attachments[${appendIndex}].file`, attachment);
          formData.append(`Attachments[${appendIndex}].statusId`, 0);
          appendIndex++;
        }
      });
      //deleted file
      id &&
        notificationData?.attachments?.forEach((attachment) => {
          const isFound = values.Attachments.some(
            (file) => file.attachmentId === attachment.attachmentId
          );

          if (!isFound) {
            formData.append(
              `Attachments[${appendIndex}].fileId`,
              attachment.attachmentId
            );
            formData.append(`Attachments[${appendIndex}].statusId`, 2);
            appendIndex++;
          }
        });

      // Append other fields
      Object.keys(values).forEach((key) => {
        if (key !== "Attachments") {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        }
      });
      formData.append("IsCampaign", isCampaign);

      addEmail(formData, {
        onSuccess: () => {
          toast.success("Email template saved successfully!");
          if (isCampaign) {
            navigate("/home/campaign-templateEmail");
          } else {
            navigate("/home/email");
          }
          refetchNotificationById();
        },
        onError: (error) => {
          toast.error("Failed to save email template.");
          console.error("Error:", error);
        },
      });
    },
  });
  const getNotificationType = (notificationTypeId) => {
    const notification = emailTypesData?.data?.find(
      (item) => item.notificationTypeId === notificationTypeId
    );
    return notification
      ? notification.notificationType
      : "Notification Type Not Found";
  };
  useEffect(() => {
    const selectedType = emailTypesData?.data?.find(
      (item) => item.notificationTypeId === formik.values.NotificationType
    );
    setRequiresOneActive(selectedType?.requiresOneActive || false);
  }, [formik.values.NotificationType, emailTypesData]);

  const cautionImg = () => {
    if (!formik.values.NotificationType || !requiresOneActive) {
      return null;
    }

    const tooltipContent = formik.values.IsActive
      ? "This template type requires only one email template to be active. You cannot deactivate this template, only activate another."
      : `This template type requires only one email template to be active. If you activate this template, all other "${
          getNotificationType(formik.values.NotificationType) || ""
        }" templates will change to inactive.`;

    return (
      <Tooltip content={tooltipContent} padding>
        <img src={CautionIcon} alt="Caution" />
      </Tooltip>
    );
  };
  console.log(
    "formik.values.NotificationContent",
    formik.values.NotificationContent
  );
  if (isLoading) {
    return <Loader />;
  }
  console.log("vdfkvmd", formik?.values);
  return (
    <WrappingContainer>
      <FormikProvider>
        <WhiteContainer white>
          <TitlePageHeader
            title={
              notificationData?.notificationName != null
                ? "Update Email Template"
                : "Create New Email Template"
            }
            buttonText={
              notificationData?.notificationName != null ? "Update" : "Save"
            }
            handleOnClick={formik.handleSubmit}
            isLoading={isAdding}
          />
          <div className={styles["new-email-cont"]}>
            <TextInput
              label="Name"
              placeholder="Enter Email Name"
              icon={emailICon}
              name="NotificationName"
              value={formik.values.NotificationName}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.NotificationName}
              touched={formik.touched.NotificationName}
            />
            <div className={styles["newEmail-sub"]}>
              {!isCampaign && (
                <CustomizedSelect
                  label="Email Type"
                  placeholder="Select Type"
                  options={selectApplicantOptions}
                  icon={emailICon}
                  name="NotificationType"
                  value={formik.values.NotificationType}
                  onChange={(name, value) => {
                    formik.setFieldValue(name, value);
                  }}
                  errors={formik.errors.NotificationType}
                  touched={formik.touched.NotificationType}
                />
              )}{" "}
              <div className={styles["status-cont"]}>
                <p>Status</p>
                <div className={styles["status-subcont"]}>
                  <div>
                    <Toggle
                      isActive={formik.values.IsActive}
                      onChange={() =>
                        formik.setFieldValue(
                          "IsActive",
                          !formik.values.IsActive
                        )
                      }
                    />
                  </div>
                  {cautionImg(formik.values.IsActive)}
                </div>
              </div>
            </div>
            <TextInput
              label="Subject"
              placeholder="Enter Email Subject"
              icon={subjectICon}
              name="Subject"
              value={formik.values.Subject}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.Subject}
              touched={formik.touched.Subject}
            />{" "}
            <Attachments
              name="Attachments"
              value={formik.values.Attachments}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.Attachments}
              touched={formik.touched.Attachments}
              stopAdding={formik.values.Attachments?.length > 4}
            />
          </div>

          <div styles={{ minHeight: "200px" }}>
            <TinyMCEEditor
              name="NotificationContent"
              value={formik.values.NotificationContent}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.NotificationContent}
              touched={formik.touched.NotificationContent}
            />
          </div>

          <div className={styles["new-email-cont"]}>
            {" "}
            <CustomizedSelect
              label="Email Signature"
              placeholder="Select E-signature"
              options={eSignature?.data?.map(
                ({ emailSignatureId, signatureName }) => ({
                  value: emailSignatureId,
                  text: signatureName,
                })
              )}
              icon={emailICon}
              name="EmailSignatureId"
              value={formik.values.EmailSignatureId}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.EmailSignatureId}
              touched={formik.touched.EmailSignatureId}
            />
          </div>
        </WhiteContainer>
      </FormikProvider>
    </WrappingContainer>
  );
};

export default NewTemplateEmail;
